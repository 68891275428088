import { Link } from "@react-navigation/native"
import {Modal, View, Text, StyleSheet, Pressable, Linking} from "react-native"
import AntDesign from 'react-native-vector-icons/AntDesign'

export function HelpModal({visible, setVisible}) {
    return (
        <Modal transparent visible={visible}>
            <View style={styles.outerView}>
                <View style={styles.innerView}>
                    <View style={{justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
                        <Text style={styles.headerText}>What is this?</Text>
                        <Pressable onPress={() => setVisible(false)}>
                            <AntDesign name="close" size={25} color={"gray"} />
                        </Pressable>
                    </View>
                    <Text style={{fontFamily: "PoppinsRegular", marginTop: 15}}>
                        billing.bh is an invoice payment service allowing clients to quickly and easily pay their legal fees online.
                    </Text>
                    <Text style={{fontFamily: "PoppinsRegular", marginTop: 10}}>
                        billing.bh is owned by Consult Me. You can visit our website and our range of legal consultants at <Text onPress={() => Linking.openURL("https://www.consultmebh.com")} style={{color: 'blue'}}>https://consultmebh.com</Text>
                    </Text>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    outerView: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        backgroundColor: 'white',
        padding: 25,
        marginHorizontal: 10,
        borderColor: '#b0b0b0',
        borderWidth: 1,
        borderRadius: 10,
        maxWidth: 500
    },
    headerText: {
        fontFamily: "sans-serif",
        fontSize: 22,
        fontWeight: '600'
    }
})