import {View, Text, Image} from "react-native"
import {API_URL} from "@env"

function GenericInitialProfilePicture({ name, style, textStyle }) {
    var palettes = ["rgba(45, 123, 196, 0.7)", "rgba(42, 201, 125, 0.7)", "rgba(232, 213, 90, 0.7)"]
    var num = 0

    var initials = ""
    var n = name.split(' ')
    for (var i = 0; i < n.length; ++i) {
        var char = n[i][0].toUpperCase()
        initials = initials + char
        num += char.charCodeAt(0)
    }

    return (
        <View style={[{ backgroundColor: palettes[num % 3], justifyContent: 'center', alignItems: 'center' }, style]}>
            <Text style={[{ fontSize: 45, color: 'white', fontFamily: 'Arial, sans-serif', userSelect: 'none' }, textStyle]} allowFontScaling={false}>{initials}</Text>
        </View>
    )
}

export function ProfilePicture({ isProfilePicture, userId, name, style, textStyle }) {
    return (
        <>
            {
                isProfilePicture ? <Image source={{ uri: `${API_URL}/api/user/${userId}/${isProfilePicture}/profile-picture` }} style={style} /> :
                    <GenericInitialProfilePicture name={name} style={style} textStyle={textStyle} />
            }
        </>
    )
}