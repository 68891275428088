import { useEffect, useState } from "react"
import { View, Text, StyleSheet, useWindowDimensions, ScrollView, Image, Pressable, TouchableOpacity, ActivityIndicator, Linking } from "react-native"
import { API_URL } from "@env"
import { ProfilePicture } from "./ProfilePicture"
import AntDesign from 'react-native-vector-icons/AntDesign'

function BaseButton({ title, style, textStyle, viewStyle, isLoading, onPress, disabled }) {
    return (
        <TouchableOpacity onPress={onPress} style={style} disabled={disabled}>
            <View style={[viewStyle, disabled ? { opacity: 0.5 } : null]}>
                {isLoading ? <ActivityIndicator size="small" color={"#cfcfcf"} /> :
                    <Text adjustsFontSizeToFit numberOfLines={1} style={textStyle}>{title}</Text>
                }
            </View>
        </TouchableOpacity>
    )
}

function Button({ title, onPress, style, textStyle, viewStyle, isLoading, disabled }) {
    return (
        <BaseButton style={style}
            viewStyle={[styles.primaryButton1, viewStyle]}
            textStyle={[{ fontWeight: 'bold', color: 'white' }, textStyle]}
            title={title}
            onPress={onPress}
            isLoading={isLoading}
            disabled={disabled}
        />
    )
}

function ProfileSection({ invoiceInfo }) {
    return (
        <View style={styles.profileSection}>
            <ProfilePicture
                isProfilePicture={invoiceInfo["issuer"]["profile_picture"]}
                userId={invoiceInfo["issuer"]["id"]}
                name={invoiceInfo["issuer"]["display_name"]}
                style={{ width: 45, height: 45, borderRadius: 100 }}
                textStyle={{ fontSize: 18 }}
            />
            <View>
                <Text style={{ fontSize: 11, fontFamily: "PoppinsLight" }}>INVOICE FROM</Text>
                <Text style={{ fontFamily: "PoppinsSemiBold", letterSpacing: 0.3, fontSize: 12 }}>{invoiceInfo["issuer"]["display_name"]}</Text>
            </View>
        </View>
    )
}

function PaymentMethod({ id, selected, onSelect, children, loading }) {
    return (
        <Pressable onPress={() => {
            if (!loading) {
                onSelect(id)
            }
        }}>
            <View style={[styles.paymentMethod, selected == id ? { borderWidth: 3 } : null]}>
                {children}
            </View>
        </Pressable>
    )
}

function PaymentMethods({ selectedPaymentMethod, setSelectedPaymentMethod, loading }) {
    return (
        <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ paddingVertical: 6 }} contentContainerStyle={{ gap: 10 }}>
            <PaymentMethod id="benefit" selected={selectedPaymentMethod} onSelect={setSelectedPaymentMethod} loading={loading}>
                <Image source={require('./assets/benefit.png')} style={{ width: 28, height: 40 }} />
            </PaymentMethod>
            <PaymentMethod id="applepay" selected={selectedPaymentMethod} onSelect={setSelectedPaymentMethod} loading={loading}>
                <Image source={require('./assets/apple_pay.png')} style={{ width: 62.64, height: 40 }} />
            </PaymentMethod>
            <PaymentMethod id="card" selected={selectedPaymentMethod} onSelect={setSelectedPaymentMethod} loading={loading}>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <AntDesign name="creditcard" size={25} />
                    <Text style={{ fontSize: 11, fontFamily: "PoppinsRegular", userSelect: 'none' }}>Credit Card</Text>
                </View>
            </PaymentMethod>
        </ScrollView>
    )
}

function Breakdown({ invoiceInfo }) {
    return (
        <View style={{ gap: 5 }}>
            <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontFamily: "PoppinsLight", fontSize: 12 }}>
                    Amount
                </Text>
                <Text style={{ fontFamily: "PoppinsMedium", fontSize: 12 }}>
                    {invoiceInfo["amount"].toFixed(2)} BD
                </Text>
            </View>
            <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontFamily: "PoppinsLight", fontSize: 12 }}>
                    Service charge
                </Text>
                <Text style={{ fontFamily: "PoppinsMedium", fontSize: 12 }}>
                    {invoiceInfo["fee"].toFixed(2)} BD
                </Text>
            </View>
            <View style={{ borderBottomWidth: 1, borderStyle: 'dashed', marginVertical: 3 }} />
            <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontFamily: "PoppinsLight", fontSize: 12 }}>
                    Total
                </Text>
                <Text style={{ fontFamily: "PoppinsMedium", fontSize: 12 }}>
                    {(invoiceInfo["amount"] + invoiceInfo["fee"]).toFixed(2)} BD
                </Text>
            </View>
        </View>
    )
}

function PaymentCompleted({ invoiceInfo }) {
    return (
        <View style={{ marginVertical: 8, alignItems: 'center' }}>
            <Image source={require('./assets/correct.png')} style={{ width: 75, height: 75 }} />
            <Text style={{ marginTop: 8, fontFamily: "PoppinsRegular", color: 'green', textAlign: 'center' }}>
                Your payment has been successfully completed.
            </Text>
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>
                    Reference ID: {invoiceInfo["reference_id"]}
                </Text>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>
                    Paid on: {new Date(invoiceInfo["completed_on"] * 1000).toString()}
                </Text>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>
                    Support: help@consultmebh.com
                </Text>
            </View>
        </View>
    )
}

function PaymentPending({ invoiceInfo }) {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
    const [loading, setLoading] = useState(false)

    const getPayText = () => {
        if (selectedPaymentMethod == "benefit") {
            return "Pay with Benefit"
        } else if (selectedPaymentMethod == "card") {
            return "Pay with Credit Card"
        } else if (selectedPaymentMethod == "applepay") {
            return "Pay with Apple Pay"
        } else {
            return "Pay Invoice"
        }
    }

    const startCharge = async () => {
        setLoading(true)
        const request = await fetch(`${API_URL}/api/invoices/${invoiceInfo["id"]}/charge`, {
            method: "POST",
            body: JSON.stringify({
                type: selectedPaymentMethod
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
        if (request.ok) {
            const response = await request.json()
            Linking.openURL(response["url"], "_self")
        } else {
            setLoading(false)
        }
    }

    return (
        <View>
            <View style={styles.section}>
                <Text style={styles.header}>Note</Text>
                <Text style={{ color: 'gray', fontSize: 12 }}>{invoiceInfo["note"] ? invoiceInfo["note"] : "No note provided."}</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.header}>Payment Method</Text>
                <PaymentMethods
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    loading={loading}
                />
            </View>
            <View style={{ marginTop: 8, paddingBottom: 8 }}>
                <Text style={styles.header}>Breakdown</Text>
                <Breakdown invoiceInfo={invoiceInfo} />
            </View>
            <View style={{ marginTop: 15 }}>
                {selectedPaymentMethod == "applepay" ? (
                    <Button onPress={startCharge} title={getPayText()} isLoading={loading} viewStyle={{ backgroundColor: 'black', height: 50 }} />
                ) : (
                    <Button onPress={startCharge} title={getPayText()} disabled={!selectedPaymentMethod} isLoading={loading} viewStyle={{ height: 50 }} />
                )}
                <Text style={styles.serviceAgreementText}>
                    By using this payment service, you agree to our <Text style={{ color: 'rgb(0, 54, 246)' }} onPress={() => Linking.openURL("/payment-agreement.pdf", "_blank")}>Payment Service Agreement</Text>
                </Text>
            </View>
        </View>
    )
}

export function Invoice({ navigation, route }) {
    const { width, height } = useWindowDimensions()
    const [invoiceInfo, setInvoiceInfo] = useState(null)
    const [failed, setFailed] = useState(false)

    const getInvoiceInfo = async () => {
        const r = await fetch(`${API_URL}/api/invoices/get-invoice-by-link?link=${route.params.invoiceLink}`)
        if (r.ok) {
            return await r.json()
        } else if (r.status == 404) {
            setFailed(true)
        }
    }

    const sendCheck = async () => {
        const r = await fetch(`${API_URL}/api/invoices/check`, {
            method: "POST",
            body: JSON.stringify({
                charge_id: route.params.tap_id
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
        if (r.ok) {
            return await r.json()
        }
    }

    const updateInfo = () => {
        getInvoiceInfo().then(info => {
            setInvoiceInfo(info)
        })
    }

    useEffect(() => {
        if (route.params.completed == "1") {
            sendCheck().then(info => {
                if (info?.status == "completed") {
                    setInvoiceInfo(info)
                } else {
                    updateInfo()
                }
            })
        } else {
            updateInfo()
        }
    }, [])

    if (failed) {
        return <Text>No invoice found.</Text>
    }

    if (!invoiceInfo) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ padding: 10, borderRadius: 5, backgroundColor: 'white' }}>
                    <ActivityIndicator size={"large"} />
                </View>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View style={[styles.invoiceSection, width > 450 ? { width: 450 } : { width: '95%' }]}>
                <ProfileSection invoiceInfo={invoiceInfo} />
                {invoiceInfo["status"] == "completed" ? (
                    <PaymentCompleted invoiceInfo={invoiceInfo} />
                ) : (
                    <PaymentPending
                        invoiceInfo={invoiceInfo}
                    />
                )}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
    },
    invoiceSection: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 5,
    },
    section: {
        marginTop: 8,
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: 'rgb(242, 242, 242)'
    },
    paymentMethod: {
        width: 95,
        height: 60,
        borderWidth: 1,
        borderRadius: 15,
        borderColor: '#d6d6d6',
        alignItems: 'center',
        justifyContent: 'center'
    },
    primaryButton1: {
        backgroundColor: 'rgb(0, 54, 246)',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        width: '100%'
    },
    header: {
        fontFamily: "PoppinsSemiBold",
        marginBottom: 5,
        fontSize: 14
    },
    serviceAgreementText: {
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 10,
        textAlign: 'center',
        marginTop: 10
    },
    profileSection: {
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingBottom: 8,
        borderBottomColor: 'rgb(242, 242, 242)'
    }
})