import { Link, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useFonts } from "expo-font";
import { useState } from 'react';
import { Linking, Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import 'react-native-gesture-handler';
import Feather from 'react-native-vector-icons/Feather';
import { HelpModal } from "./HelpModal";
import { Invoice } from './Invoice';

const Stack = createStackNavigator()

function Selectable({ onPress, text, external }) {
  const [hovered, setHovered] = useState(false)

  return (
    <Pressable onPress={onPress} onHoverIn={() => setHovered(true)} onHoverOut={() => setHovered(false)}>
      <View style={[{paddingHorizontal: 20, paddingVertical: 10, borderRadius: 100, flexDirection: 'row' }, hovered && {backgroundColor: 'rgba(227, 227, 227, 0.4)'}]}>
        <Text style={{ fontFamily: "PoppinsMedium", fontSize: 16, userSelect: 'none' }}>{text}</Text>
        {external ? (
          <Feather name="arrow-up-right" size={11} />
        ) : null}
      </View>
    </Pressable>
  )
}

function SelectableLink({ to, onPress, text, external }) {
  const [hovered, setHovered] = useState(false)

  return (
    <Pressable onPress={() => Linking.openURL(to)} onHoverIn={() => setHovered(true)} onHoverOut={() => setHovered(false)}>
      <Link to={to}>
        <View style={[{paddingHorizontal: 20, paddingVertical: 10, borderRadius: 100, flexDirection: 'row' }, hovered && {backgroundColor: 'rgba(227, 227, 227, 0.4)'}]}>
          <Text style={{ fontFamily: "PoppinsMedium", fontSize: 16, userSelect: 'none' }}>{text}</Text>
          {external ? (
            <Feather name="arrow-up-right" size={11} />
          ) : null}
        </View>
      </Link>
    </Pressable>
  )
}
function HomePage({ navigation }) {
  const [visible, setVisible] = useState(false)
  const { width, height } = useWindowDimensions()

  const getTitleFontSize = () => {
    if (width >= 500) {
      return 90
    } else {
      return 70
    }
  }

  const getDescriptionFontSize = () => {
    if (width >= 500) {
      return 45
    } else {
      return 30
    }
  }

  return (
    <View style={styles.container}>
      <HelpModal visible={visible} setVisible={setVisible} />
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ fontFamily: "PoppinsBold", fontSize: getTitleFontSize(), color: 'rgb(255, 8, 46)', textAlign: 'center' }}>
          billing
          <Text style={{ fontFamily: "PoppinsBold", fontSize: getTitleFontSize(), textAlign: 'center', color: 'black' }}>.bh</Text>
        </Text>
      </View>
      <Text style={{ fontFamily: "PoppinsSemiBold", fontSize: getDescriptionFontSize(), textAlign: 'center' }}>is owned by Consult Me</Text>

      <View style={{ flexDirection: 'row', marginTop: 15 }}>
        <Selectable onPress={() => setVisible(true)} text={"What is this?"} />
        <SelectableLink to={"https://www.consultmebh.com"} onPress={() => Linking.openURL("https://www.consultmebh.com", "_blank")} text={"Consult Me"} external />
        <SelectableLink to={"/payment-agreement.pdf"} onPress={() => Linking.openURL("/payment-agreement.pdf", "_blank")} text={"Legal"} external />
      </View>
    </View>
  )
}

function StackNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={HomePage} options={{ title: "billing.bh" }} />
      <Stack.Screen name="Invoice" component={Invoice} options={{ title: "Invoice" }} />
    </Stack.Navigator>
  )
}

export default function App() {
  const [fontsLoaded] = useFonts({
    PoppinsBold: require('./assets/fonts/Poppins-Bold.ttf'),
    PoppinsSemiBold: require('./assets/fonts/Poppins-SemiBold.ttf'),
    PoppinsLight: require('./assets/fonts/Poppins-Light.ttf'),
    PoppinsMedium: require('./assets/fonts/Poppins-Medium.ttf'),
    PoppinsRegular: require('./assets/fonts/Poppins-Regular.ttf'),
    NotoSansBold: require('./assets/fonts/NotoSansArabic-Bold.ttf'),
    NotoSansLight: require('./assets/fonts/NotoSansArabic-Light.ttf'),
    NotoSansMedium: require('./assets/fonts/NotoSansArabic-Medium.ttf'),
    NotoSansRegular: require('./assets/fonts/NotoSansArabic-Regular.ttf'),
    NotoSansSemiBold: require('./assets/fonts/NotoSansArabic-SemiBold.ttf')
  })

  const config = {
    screens: {
      Main: {
        screens: {
          Home: '/',
          Invoice: {
            path: '/:invoiceLink'
          }
        }
      }
    }
  }

  const linking = {
    config
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Main" component={StackNavigator} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
